import { useRef, useEffect } from 'react';
import './Textarea.module.scss';

function calculateHeight(value) {
  let numberOfLineBreaks = (value.match(/\n/g) || []).length;
  // min-height + lines x line-height + padding + border
  let newHeight = 30 + numberOfLineBreaks * 23 + 32 + 2;
  return newHeight;
}

const Textarea = (props) => {
  const {
    label,
    // eslint-disable-next-line no-unused-vars
    error,
    dataTestId,
    customClassNames,
    name,
    isFullyExtendableByTyping,
    value,
    className: parentClassName,
    ...rest
  } = props;

  const textareaRef = useRef();

  const updateHeight = () => {
    const updatedHeight =
      calculateHeight(textareaRef.current.value) + 'px';
    if (textareaRef.current.style.height !== updatedHeight) {
      textareaRef.current.style.height = updatedHeight;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.value = value;
      updateHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const className = 'c-Textarea';
  return (
    <div className={className}>
      {label && (
        <label
          className={`${className}__label text-14 font-medium font-poppins text-[#666666]`}>
          {label}
        </label>
      )}
      <textarea
        className={`${className}__textarea ${customClassNames} ${parentClassName} ${
          isFullyExtendableByTyping ? 'overflow-hidden resize-none' : ''
        }`}
        name={name}
        {...rest}
        ref={textareaRef}
        data-testid={`${dataTestId}-textarea-${label ?? name}`}
        onKeyUp={() => {
          if (!isFullyExtendableByTyping) {
            return;
          }
          updateHeight();
        }}
      />
      {error && (
        <p className="text-12 text-[#db3952] font-poppins ml-18 -mt-8">
          {error}
        </p>
      )}
    </div>
  );
};

Textarea.defaultProps = {
  dataTestId: '',
  customClassNames: '',
  isFullyExtendableByTyping: false
};

export default Textarea;

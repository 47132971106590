import PropTypes from 'prop-types';
/* eslint-disable jsx-a11y/no-onchange */
import classNames from 'classnames';
import style from './Select.module.scss';

const Select = ({
  label,
  placeholder,
  optionsList,
  value,
  onChange,
  error,
  dark,
  v2,
  dataTestId,
  customClassName,
  customInnerStyle,
  ...props
}) => {
  const className = 'c-Select';
  const baseClassNames = classNames(className, style[className], {
    [`${className}--error`]: !!error,
    [style[`${className}--error`]]: !!error,
    [style[`${className}--dark`]]: dark,
    [style[`${className}--v2`]]: v2
  });
  const containerClassNames = classNames(style[`${className}__container`]);

  const innerClassNames = classNames(customClassName);

  const labelClassName = `${className}__label`;
  const labelClassNames = classNames(
    labelClassName,
    style[labelClassName]
  );

  const hasOptionDisplay = optionsList.some(
    (option) => option.optionsDisplay !== undefined
  );

  return (
    <div className={baseClassNames}>
      <div className={containerClassNames + ' relative'}>
        {label ? <label className={labelClassNames}>{label}</label> : null}
        <select
          value={value}
          onChange={onChange}
          className={innerClassNames}
          style={customInnerStyle}
          {...props}
          data-testid={`${dataTestId}-select-${label}`}>
          <option value="" disabled>
            {placeholder || '--- Select ---'}
          </option>
          {optionsList.map(({ label, value: optionValue }, key) => {
            return (
              <option
                key={key}
                value={optionValue}
                selected={optionValue === value}>
                {label}
              </option>
            );
          })}
        </select>
        {hasOptionDisplay ? (
          <select
            value={value}
            onChange={onChange}
            className={innerClassNames}
            {...props}
            style={{
              ...customInnerStyle,
              position: 'absolute',
              top: '0px',
              right: '0px',
              opacity: 0
            }}
            data-testid={`${dataTestId}-select-${label}`}>
            <option value="" disabled>
              {placeholder || '--- Select ---'}
            </option>
            {optionsList.map(
              ({ optionsDisplay, value: optionValue }, key) => {
                return (
                  <option
                    key={key}
                    value={optionValue}
                    selected={optionValue === value}>
                    {optionsDisplay}
                  </option>
                );
              }
            )}
          </select>
        ) : (
          <></>
        )}
        {error ? (
          <p className={style[`${className}__error`]}>{error}</p>
        ) : null}
      </div>
    </div>
  );
};

Select.defaultProps = {
  dark: false,
  dataTestId: ''
};

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  error: PropTypes.string,
  dark: PropTypes.bool,
  dataTestId: PropTypes.string,
  customClassName: PropTypes.string,
  customInnerStyle: PropTypes.object
};

export default Select;

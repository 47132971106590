import React, { useEffect, useRef, useState } from 'react';

import EyeVisible from '../../Icons/EyeVisible';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import style from './Input.module.scss';

const Input = ({
  type,
  name,
  label,
  onChange,
  error,
  eyeIcon,
  containerIcon,
  iconLeft,
  v2,
  dataTestId,
  customClassnames,
  customErrorClassName,
  inputContainerClassName,
  customLabelClassNames,
  customInnerStyle,
  customInnerClassName,
  required,
  variant,
  focus,
  rounded,
  ...rest
}) => {
  const className = 'c-Input';
  const isStacked = variant === 'stacked';
  const baseClassNames = inputContainerClassName
    ? classNames(
        inputContainerClassName,
        style[inputContainerClassName],
        customClassnames,
        rounded ? style[`${className}--rounded`] : null
      )
    : classNames(
        className,
        style[className],
        {
          [style[`${className}--v2`]]: v2,
          [style[`${className}--v2--error`]]: v2 && error
        },
        {
          [`${className}--error`]: error,
          [style[`${className}--error`]]: error
        },
        {
          [`${className}--stacked`]: isStacked,
          [style[`${className}--stacked`]]: isStacked,
          [style[`${className}--v2--error`]]: isStacked && error
        },
        customClassnames,
        rounded ? style[`${className}__rounded`] : null
      );

  const inputRef = useRef(null);
  const [isEyeIconVisible, setIsEyeIconVisible] = useState(false);

  function toggleInputVisibility() {
    const inputType = inputRef?.current?.type;
    if (inputType === 'password') inputRef.current.type = 'text';
    else inputRef.current.type = 'password';

    setIsEyeIconVisible((prevBool) => !prevBool);
  }

  useEffect(() => {
    if (focus && inputRef.current) inputRef.current.focus();
  }, [focus]);
  function renderEyeIcon() {
    return (
      eyeIcon && (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <div
          role="button"
          className={
            iconLeft
              ? style[`${className}__container__icon_left`]
              : style[`${className}__container__icon`]
          }
          onClick={toggleInputVisibility}>
          <EyeVisible isOpen={isEyeIconVisible} />
        </div>
      )
    );
  }

  const renderContainerIcon = () => {
    return (
      <div
        className={
          iconLeft
            ? style[`${className}__container__icon_left`]
            : style[`${className}__container__icon`]
        }>
        {containerIcon}
      </div>
    );
  };

  return (
    <div className={`${className} ${baseClassNames}`}>
      {label && (
        <label htmlFor={name} className={customLabelClassNames}>
          {label}
        </label>
      )}
      <div
        className={`${className}__container 
        ${style[`${className}__container`]}`}>
        <input
          ref={inputRef}
          name={name}
          type={type}
          onChange={onChange}
          required={required}
          onWheel={(e) => {
            if (type === 'number') e.currentTarget.blur();
          }} // To prevent number from updating on scroll inside input.
          style={customInnerStyle}
          className={customInnerClassName}
          {...rest}
          data-testid={`${dataTestId}-input-${name}`}
        />
        {containerIcon && renderContainerIcon()}
        {renderEyeIcon()}
      </div>
      {error && (
        <p
          className={`${
            style[`${className}__error`]
          } ${`${className}__error`}  ${customErrorClassName}`}>
          {error}
        </p>
      )}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  name: '',
  label: '',
  error: '',
  eyeIcon: false,
  dataTestId: '',
  v2: false
};

export default Input;
